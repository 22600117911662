html{
  @extend %font-content;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

body{
  padding: 0;
  margin: 0;
  background: #ffffff;
  font-weight: 300;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+39,f3f3f3+100 */
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 39%, rgba(243,243,243,1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 39%,rgba(243,243,243,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255,255,255,1) 39%,rgba(243,243,243,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .navigation-open &{
    overflow: hidden;
  }
}


.debug{
  background: orange;
  color: #fff;
  padding: $gutter / 2;
  text-align: center;
}

.parallax-container{
  @include parallax;
}

%wrapper{
  max-width: $page-width;
  margin: auto;
  padding: 0 2rem;
}


/***************************************************************************************

  MAIN

***************************************************************************************/

*{
  box-sizing: border-box;
  outline: 0;
  //outline: 1px dotted magenta;
}

h1,h2,h3,h4,h5,h6,figure,dd,dt,dl{
  margin: 0;
  padding: 0;
  line-height: 1em;
}

p{
  margin-top: 0;
  a.more{
    @extend %button;
    margin-top: 1em;
  }
}

dl{
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  @include rem(font-size, 12px);

  .product-content &:last-child{
    border: 0;
  }

  dt{
    width: 50%;
    @include rem(padding, $gutter/2 0);
  }
  dd{
    @include rem(padding, $gutter/2 0);
    font-weight: bold;
  }
}

a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
  &:visited{
    text-decoration: none;
  }
  &:active{
    text-decoration: none;
  }
}

#loader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #fff;
  z-index: 10000000;
  text-align: center;

  > div{
    position: relative;
    top: 45%;
    margin: auto;
    display: inline-block;
    transform: rotate(45deg);
  }

  // STYLE LOADER
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 81px;
    height: 81px;
    margin: 3px;
    border: 3px solid $color-main;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color-secondary transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#page{
  position: relative;
  transform: none !important;
  overflow: hidden;

  .navigation-open &{
    height: 100vh;
  }

  .main-header{
    background: #fff;

    > .wrapper{
      position: relative;
      font-size: 0px;
      @extend %wrapper;
      padding-left: 200px;
      display: flex;
      align-items: center;
      padding-right: 0;
    }

    .navigation-minified &{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 15000;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    }
  }
  .main-body{
    > div{
      header{
        > .wrapper {
          @extend %wrapper;
          padding-top: calc(#{$gutter * 6} + #{$gutter-section});
          text-align: center;

          h1{
            @extend %title;
          }
        }
      }
    }

    .navigation-open &{
      filter: blur(5px);
    }
  }
  .main-footer{
    background: #000;
    position: relative;
    padding-top: $gutter-section / 2;
    padding-bottom: $gutter-section / 2;

    > div {
      > .wrapper {
        @extend %wrapper;
      }
    }
  }
}



.nav-mobile{
  position: relative;
  display: none;
  margin-left: auto;
  background: #000;
  width: 60px;
  height: 60px;
  > span{
    @include menu-burger;
  }
  .navigation-open &{
    > span{
      @include menu-burger-active;
    }
  }
  .navigation-minified &{
    display: block;
  }
  > span > span{
    background: #fff;
  }
}



.navigation-overlay{
  background: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 5000;
}
