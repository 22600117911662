@font-face {
	font-family: "icon";
	src: url('../fonts/icons/icon.eot');
	src: url('../fonts/icons/icon.eot?#iefix') format('eot'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
}

.icon:before {
	font-family: "icon";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-arrow-down:before {
	content: "\E001";
}

.icon-arrow-left:before {
	content: "\E002";
}

.icon-arrow-right:before {
	content: "\E003";
}

.icon-arrow-up:before {
	content: "\E004";
}

.icon-arrow:before {
	content: "\E005";
}

.icon-badge:before {
	content: "\E006";
}

.icon-bag:before {
	content: "\E007";
}

.icon-calendar:before {
	content: "\E008";
}

.icon-chat-circle-exclamation:before {
	content: "\E009";
}

.icon-chat-circle-group:before {
	content: "\E00A";
}

.icon-chat-circle-information:before {
	content: "\E00B";
}

.icon-chat-circle-question:before {
	content: "\E00C";
}

.icon-chat-circle:before {
	content: "\E00D";
}

.icon-chat-square-exclamation:before {
	content: "\E00E";
}

.icon-chat-square-group:before {
	content: "\E00F";
}

.icon-chat-square-information:before {
	content: "\E010";
}

.icon-chat-square-question:before {
	content: "\E011";
}

.icon-chat-square:before {
	content: "\E012";
}

.icon-check-circle:before {
	content: "\E013";
}

.icon-clock:before {
	content: "\E014";
}

.icon-close:before {
	content: "\E015";
}

.icon-cog:before {
	content: "\E016";
}

.icon-company:before {
	content: "\E017";
}

.icon-coupon:before {
	content: "\E018";
}

.icon-crate:before {
	content: "\E019";
}

.icon-credit-card:before {
	content: "\E01A";
}

.icon-delete:before {
	content: "\E01B";
}

.icon-download:before {
	content: "\E01C";
}

.icon-dropbox:before {
	content: "\E01D";
}

.icon-earth:before {
	content: "\E01E";
}

.icon-eco:before {
	content: "\E01F";
}

.icon-email-check:before {
	content: "\E020";
}

.icon-email-forward:before {
	content: "\E021";
}

.icon-email-read:before {
	content: "\E022";
}

.icon-email:before {
	content: "\E023";
}

.icon-example:before {
	content: "\E024";
}

.icon-expand-circle-plus:before {
	content: "\E025";
}

.icon-expand-plus:before {
	content: "\E026";
}

.icon-expand:before {
	content: "\E027";
}

.icon-facebook-fill:before {
	content: "\E028";
}

.icon-facebook:before {
	content: "\E029";
}

.icon-file-add:before {
	content: "\E02A";
}

.icon-file-edit:before {
	content: "\E02B";
}

.icon-files:before {
	content: "\E02C";
}

.icon-filter:before {
	content: "\E02D";
}

.icon-folder:before {
	content: "\E02E";
}

.icon-graph-pie:before {
	content: "\E02F";
}

.icon-group:before {
	content: "\E030";
}

.icon-heart:before {
	content: "\E031";
}

.icon-home:before {
	content: "\E032";
}

.icon-hugo-earth:before {
	content: "\E033";
}

.icon-hugo-heart:before {
	content: "\E034";
}

.icon-hugo-kids:before {
	content: "\E035";
}

.icon-hugo-water:before {
	content: "\E036";
}

.icon-instagram-fill:before {
	content: "\E037";
}

.icon-instagram:before {
	content: "\E038";
}

.icon-keys:before {
	content: "\E039";
}

.icon-layers:before {
	content: "\E03A";
}

.icon-like:before {
	content: "\E03B";
}

.icon-linkedin-fill:before {
	content: "\E03C";
}

.icon-linkedin:before {
	content: "\E03D";
}

.icon-list:before {
	content: "\E03E";
}

.icon-location-direction:before {
	content: "\E03F";
}

.icon-location-pin:before {
	content: "\E040";
}

.icon-location:before {
	content: "\E041";
}

.icon-lock-open:before {
	content: "\E042";
}

.icon-lock:before {
	content: "\E043";
}

.icon-logout:before {
	content: "\E044";
}

.icon-money:before {
	content: "\E045";
}

.icon-navigation:before {
	content: "\E046";
}

.icon-notification:before {
	content: "\E047";
}

.icon-paypal:before {
	content: "\E048";
}

.icon-pencil:before {
	content: "\E049";
}

.icon-person-add:before {
	content: "\E04A";
}

.icon-person:before {
	content: "\E04B";
}

.icon-phone:before {
	content: "\E04C";
}

.icon-picture:before {
	content: "\E04D";
}

.icon-pinterest-fill:before {
	content: "\E04E";
}

.icon-plus:before {
	content: "\E04F";
}

.icon-refresh:before {
	content: "\E050";
}

.icon-search:before {
	content: "\E051";
}

.icon-shopping-bag:before {
	content: "\E052";
}

.icon-shopping-basket:before {
	content: "\E053";
}

.icon-shopping-cart:before {
	content: "\E054";
}

.icon-smiley-smile:before {
	content: "\E055";
}

.icon-star-fill:before {
	content: "\E056";
}

.icon-star:before {
	content: "\E057";
}

.icon-tags:before {
	content: "\E058";
}

.icon-thumbnail:before {
	content: "\E059";
}

.icon-tick:before {
	content: "\E05A";
}

.icon-truck-delivery-time:before {
	content: "\E05B";
}

.icon-truck:before {
	content: "\E05C";
}

.icon-twitter-fill:before {
	content: "\E05D";
}

.icon-twitter:before {
	content: "\E05E";
}

.icon-user:before {
	content: "\E05F";
}

.icon-video:before {
	content: "\E060";
}

.icon-voucher:before {
	content: "\E061";
}

.icon-whatsapp-fill:before {
	content: "\E062";
}

.icon-whatsapp:before {
	content: "\E063";
}

.icon-youtube-fill:before {
	content: "\E064";
}

.icon-youtube:before {
	content: "\E065";
}
