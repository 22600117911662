
@mixin unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  outline: 0px;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin clearlist {
  list-style:none;
  margin:0;
  padding:0;
  > li{
    list-style:none;
    margin:0;
    padding:0;
  }
}

@mixin list {
  display: flex;
  flex-wrap: wrap;
  > dt, > dd{
    width: 50%;
  }
}

@mixin rating-stars{
  $width: 30px;
  position: relative;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  .rating-value,
  .rating-max{
    float:left;
    span{
      margin: 0;
      color: #eeeeee;
      font-size: $width;
      overflow: hidden;
      width: $width;
      height: $width;
      line-height: $width;
      display: table-cell;
      text-align: center;
      i{
        width: $width;
      }
    }
  }
  .rating-value{
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    > div{
      width: $width * 5;
    }
    span{
      color: #ffc600;
    }
  }
  .rating-information{
    @include rem(margin-left, $gutter / 2);
    @include rem(font-size, 14px);
    line-height: $width;
    display: inline-block;
    span{
      font-weight: 600;
    }
  }
}


@mixin animate {
  transition: all 200ms ease-in-out;
}


@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}


@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}


@mixin parallax{
  overflow: inherit !important;
  background: transparent !important;

  &.parallax-clip{
    clip: rect(0, auto, auto, 0);
  }

  .parallax{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110%;
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    transform: translateY(-50%) translateX(-50%);
  }
}


@mixin grid($g: $gutter-grid){
  //@include clearlist;
  display: flex;
  align-content: center;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  flex-grow: inherit !important;
  flex: inherit !important;

  > *{
    list-style: none !important;
    //margin: 0;
    //padding: 0;
    flex-grow: 1;
    width: auto;
    //@include rem(margin-bottom, $g/2 !important );
    //overflow: hidden;
    //outline: 1px dashed rgba(0,0,0,0.3);
  }
}

@mixin grid-auto($g: $gutter-grid){
  $current: 2;
  @include grid($g);
  flex-wrap: wrap;
  //justify-content: inherit;
  flex-wrap: wrap;
  > *{
    //outline: red 1px solid;
    flex: 1;
    flex-grow: 1;
    //padding: $g;
    //@include rem(width, calc((100% / #{$current}) - #{$g / $current * ($current - 1)}) );

    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}

@mixin grid-2($g: $gutter-grid){
  $current: 2;
  @include grid($g);
  flex-wrap: wrap;
  > *{
    flex-grow: inherit !important;
    flex: inherit !important;
    @include rem(width, calc((100% / #{$current}) - #{$g / $current * ($current - 1)}) );

    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-3($g: $gutter-grid){
  $current: 3;
  @include grid($g);
  flex-wrap: wrap;
  > *{
    flex: inherit !important;
    flex-grow: inherit !important;
    @include rem(width, calc((100% / #{$current}) - #{$gutter-grid / $current * ($current - 1)}) );

    @include mq-s{
      @include rem(width, calc(50% - #{$gutter-grid / $current}) );
    }
    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-4($g: $gutter-grid){
  $current: 4;
  @include grid($g);
  flex-wrap: wrap;
  > *{
    flex: inherit !important;
    flex-grow: inherit !important;
    @include rem(width, calc((100% / #{$current}) - #{$gutter-grid / $current * ($current - 1)}) );

    @include mq-s{
      @include rem(width, calc(50% - #{$gutter-grid / 2}) );
    }
    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-6($g: $gutter-grid){
  $current: 6;
  @include grid($g);
  flex-wrap: wrap;
  > *{
    flex: inherit;
    flex-grow: inherit;
    @include rem(width, calc((100% / #{$current}) - #{$gutter-grid / $current * ($current - 1)}) );

    @include mq-s{
      @include rem(width, calc(50% - #{$gutter-grid / 2}) );
    }
    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-4-no{
  @include grid-4;
  justify-content: inherit;
  > *{
    margin: 0 !important;
    width: 25%;

    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-3-no{
  @include grid-3;
  justify-content: inherit;
  > *{
    margin: 0 !important;
    width: 33.33%;

    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-2-no{
  @include grid-2;
  justify-content: inherit;
  > *{
    margin: 0 !important;
    width: 50%;

    @include mq-xs{
      @include rem(width, calc(100%) );
    }
  }
}
@mixin grid-nowrap{
  flex-wrap: nowrap;
}
@mixin grid-center{
  > *{
    margin-left: auto;
    margin-right: auto;
  }
}


@mixin notice{
  display: block;
  @include rem(margin-bottom, $gutter);
  @include rem(padding, $gutter);
  background: $color-info;
  display: none;

  &.error{
    color: darken($color-danger, 10%);
    background: lighten($color-danger, 45%);

    strong{
      color: darken($color-danger, 25%);
    }
  }

  &.success{
    color: darken($color-success, 10%);
    background: lighten($color-success, 45%);

    strong{
      color: darken($color-success, 25%);
    }
  }
}

@mixin menu-burger{
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  $width: 3px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  span{
    transition: all 150ms ease-in-out;
    position: absolute;
    height: $width;
    width: 80%;
    background: #000;
    left: 0;
    margin-left: 10%;
    top: calc(50% - #{$width});

    &:nth-child(2){
      top: calc(50% - #{$width*4});
      transform-origin: right;
    }

    &:nth-child(3){
      top: calc(50% + #{$width*2});
    }
  }
}
@mixin menu-burger-active{
  $width: 3px;
  span{
    &:nth-child(1){
      opacity: 0;
    }

    &:nth-child(2){
      top: calc(20% - #{$width});
      transform: rotate(-45deg);
      margin-left: 0;
    }

    &:nth-child(3){
      top: calc(50% - #{$width});
      transform: rotate(45deg);
    }
  }
}

@mixin form-inline{
  flex-direction: row;
}

@mixin form-no-label{
  .form-label{
    display: none;
  }
}

@mixin form-field{
  @include rem(margin-bottom, $gutter);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;

  .error-msg{
    display: none;
    @include rem(font-size, 13px);
    @include rem(margin-top, $gutter/4);
    font-weight: bold;
    color: $color-danger;
  }
  &.form-required{
    label{
      font-weight: 400;
      &:after{
        content: "*";
        margin-left: 5px;
      }
    }
  }
  &.form-error{
    .error-msg{
      display: block;
    }
    .form-label{
      color: $color-danger !important;
    }
    .form-input {
      .form-input-wrapper {
        border-color: $color-danger !important;
      }
    }
  }
  .form-label{
    display: block;
    flex-grow: 1;
    line-height: 2em;
    white-space: nowrap;
  }
  .form-input{
    flex-grow: 1;
    .form-input-wrapper{
      border: 1px solid #aaa;
      border-radius: 3px;
      min-width: 150px;

      input[type='text'],
      input[type='password'],
      input[type='number'],
      input[type='phone'],
      input[type='email'],
      select,
      textarea{
        @include rem(font-size, 100%);
        text-align: left;
        border: 0;
        background: none;
        width: 100%;
        padding: 10px;
      }
    }
  }
  &.form-select,
  &.form-select-multiple{
    cursor: pointer;
    .form-input{
      .form-input-wrapper{
        position: relative;
        select{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        > div{
          @include animate;
          position: absolute;
          top: 0%;
          left: 0;
          z-index: 1000;
          width: auto;
          background: #fff;
          box-shadow: 0px 2px 2px rgba(0,0,0,0.1);
          min-width: 150px;
          display: none;
          margin-top: 1px;
          opacity: 0;

          > button{
            display: block;
            border: 0;
            background: #eee;
            text-align: center;
            line-height: 30px;
            text-transform: uppercase;
            margin: 5px;
            width: calc(100% - 10px);
          }

          > input{
            outline: 0;
          }

          > div{
            max-height: 200px;
            overflow: auto;
          }

          label{
            display: block;
            white-space: nowrap;
            padding: 10px;
            border-bottom: rgba(0,0,0,0.05) 1px solid;

            input{
              margin-right: 10px;
            }

            &:hover{
              background: rgba(0,0,0,0.05);
            }
          }
        }
        > span{
          display: block;
          padding: 0 10px;
          padding-right: 40px;
          line-height: 30px;
        }

      }

      i{
        position: absolute;
        top: 50%;
        right: 10px;
        color: #555;
        font-size: 12px;
        transform: translateY(-50%);
      }

      label{
        display: block;
      }
    }

    &.active{
      .form-input .form-input-wrapper > div{
        @include animate;
        display: block;
        top: 100%;
        opacity: 1;
      }
    }
  }
  &.form-color{
    .form-input{
      .form-input-wrapper{
        margin: 0;
        padding: 0;
        border: 0;
        background: none !important;
        @include clearfix;
        > *{
          padding: 0;
          display: flex;
          align-items: center;
          margin-right: $gutter/2;
          float: left;
          position: relative;
          .label{
            display: none;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            color: $color-black;
            @include rem(font-size, 13px);
            line-height: 1em;
            background: #fff;
            padding: 10px;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.5);
            text-align: center;
            border-radius: 4px;
            > i{
              display: block;
              opacity: 0.5;
            }
          }
          &:hover{
            .label{
              display: block;
            }
          }
          .color{
            float: left;
            border-radius: 50%;
            border: 2px solid #eee;
            padding: 2px;
            position: relative;
            width: 38px;
            height: 38px;
            cursor: pointer;

            &:hover{
              transform: scale(1.1);
            }

            > i{
              display: inline-block;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              background: no-repeat center;
              background-size: cover;
            }
          }
          &.active{
            .color{
              border: 2px solid #000;
            }
          }
          &[class*=color]{
            &:before{
              content: "";
              position: absolute;
              border-radius: 50%;
              top: 2px;
              left: 2px;
              right: 2px;
              bottom: 2px;
              /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,ffffff+100&0.05+0,0.41+100 */
              background: -moz-linear-gradient(45deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.41) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.05) 0%,rgba(255,255,255,0.41) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(45deg, rgba(0,0,0,0.05) 0%,rgba(255,255,255,0.41) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d000000', endColorstr='#69ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            }
          }

          &.color-black{
            i{
              background: #111111 !important;
            }
          }

          &.color-white{
            i{
              background: #fff !important;
            }
          }
        }
      }
    }
  }
  &.form-disabled,
  &[disabled='disabled']{
    opacity: 0.5;
    &.form-select,
    &.form-select-multiple{
      cursor: pointer;
      .form-input{
        .form-input-wrapper {
          background: rgba(0,0,0,0.05);
        }
      }
    }
  }
}


.form-panel{
  @include form-field;
  .form-label{
    line-height: 40px !important;
    padding-right: 40px;
  }
  .form-input{
    .form-input-wrapper{
      position: relative;

      i{
        position: absolute;
        bottom: 100%;
        right: 10px;
        color: #555;
        font-size: 12px;
        line-height: 40px;
        border: 0;
      }

    }
  }

  .filter-panel{
    $header-height: 60px;

    position: fixed;
    top: 100%;
    left: 50%;
    //bottom: 0;
    max-height: 600px;
    height: 80vh;
    width: 80vw;
    max-width: 600px;
    background: rgba(255,255,255,1);
    z-index: 20000;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    flex-direction: column;
    justify-content: stretch;
    transform: translateX(-50%) translateY(50%);
    display: flex;
    overflow: hidden;
    @include rem(font-size, 16px);

    .form-panel.active &{
      //display: block;
    }

    > *{
      padding: $gutter;
      position: relative;
    }

    header{
      padding: 0;
      border-bottom: 1px solid #eee;
      h6{
        text-transform: uppercase;
        @include rem(font-size, 14px);
        line-height: $header-height;
        padding-left: $gutter;
        @include animate;
      }

      .bt-close{
        @include animate;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: 0;
        @include rem(font-size, 20px);
        line-height: $header-height;
        width: $header-height;
      }

      .bt-back{
        @include animate;
        position: absolute;
        top: 0;
        left: 0;
        background: none;
        border: 0;
        @include rem(font-size, 20px);
        line-height: $header-height;
        width: $header-height;
      }
    }

    footer{
      button{
        @extend %button;
      }
    }

    label{
      padding: $gutter / 4;
      display: block;
      &:hover{
        background: #eee;
      }
    }

    &[data-index="0"]{
      [data-index="0"]{
        left: 0%;
      }
      [data-index="1"]{
        left: 100%;
      }
      .bt-back{
        transform: translateX(-100%);
      }
      h6{
        transform: translateX(0px);
      }
    }

    &[data-index="1"]{
      [data-index="0"]{
        left: -100%;
      }
      [data-index="1"]{
        left: 0%;
      }
      .bt-back{
        transform: translateX(0%);
      }
      h6{
        transform: translateX($header-height/2);
      }
    }

    .wrapper-main{
      height: 100%;
      max-height: 100%;
      min-width: 100%;
      position: relative;
    }

    .wrapper{
      @include animate;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: auto;

      &[data-index="0"]{
        > div{
          > ul{
            display: none;
          }
        }
      }

      &[data-index="1"]{
        > div{
          > label{
            display: none;
          }
          > ul{
            display: block;
          }
        }
      }

      > div{
        label{
          font-weight: 300;
          padding: 0 $gutter*2;
          border-bottom: 1px solid #eee;
          position: relative;
          display: flex;
          align-items: center;
          min-height: 60px;
          i.color{
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 0.5em;
            transform: translateY(4px);
            border: 2px solid #fff;
            box-shadow: 0px 0px 2px #aaa;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
          }
          figure{
            width: 80px;
            display: block;
            margin-right: 1em;
            img{
              width: 100%;
            }
          }
          span{
            display: block;
          }
          i.tick{
            opacity: 0.1;
            float: left;
            margin-right: 1em;
          }
          input{
            position: absolute;
            visibility: hidden;
            opacity: 0;

            &:checked ~ i{
              opacity: 1;
            }
            &:checked ~ span{
              font-weight: bold;
            }
          }
        }
        > label{
          font-weight: 600;
          .count{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 50%;
            background: rgba(0,0,0,0.05);
            font-size: 13px;
            font-style: normal;
            opacity: 1;

            &[rel="0"]{
              opacity: 0;
            }
          }
        }
        ul{
          @include clearlist;
          li{
            &.all{
              background: #f7f7f7;
              label{
                border-color: #aaa;
                i.tick{
                  position: relative;
                  &:after{
                    content:"";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    width: 24px;
                    height: 24px;
                    border: 1px solid #000;
                  }
                }
              }
            }
          }
          &.has-all{
            li{
              input ~ .tick{
                opacity: 1;
              }
            }
          }
        }
        &.active ul{
          display: block;
        }
      }
    }
  }
}
