
$rem-mobile-baseline: 15px;

$navigation-mobile-height: 60px;

$color-main: #1b9e40;
$color-main-light: #ddf4bb;
$color-main-dark: darken($color-main, 20%);

$color-discount: mediumvioletred;

$color-white: #ffffff;
$color-black: #222222;

$color-danger: #ff3366;
$color-warning: #ec8806;
$color-success: #79cb04;

$color-secondary: #007cc2;
$color-secondary-light: #e5f1f9;

$color-third: #bf33f3;
$color-third-light: lighten($color-third, 30%);

$page-width: 1200px;

$gutter: 20px;
$gutter-section: 6vh;
$gutter-grid: $gutter;

@import 'commons/mediaqueries';
@import 'commons/rem';
@import 'commons/mixins';
@import 'commons/default';
@import '../../fonts/icons/icons';

@import 'https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700|Love+Ya+Like+A+Sister|Schoolbell';
/*
@font-face {
  font-family: 'DK Sheepman';
  src: url('../fonts/sheepman/DKSheepman.woff2') format('woff2'),
  url('../fonts/sheepman/DKSheepman.woff') format('woff'),
  url('../fonts/sheepman/DKSheepman.ttf') format('truetype'),
  url('../fonts/sheepman/DKSheepman.svg#DKSheepman') format('svg');
  font-weight: normal;
  font-style: normal;
}
*/

%font-content {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
}

%font-special {
  font-weight: normal;
  font-family: 'Schoolbell', cursive;
  font-family: 'Love Ya Like A Sister', cursive;
}

body{
  font-weight: 300;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+39,f3f3f3+100 */
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 39%, rgba(243,243,243,1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 39%,rgba(243,243,243,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255,255,255,1) 39%,rgba(243,243,243,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

%button{
  position: relative;
  background: none;
  display: inline-block;
  border: 1px solid $color-black;
  color: $color-black;
  font-weight: 600;
  @include rem(font-size, 18px);
  text-align: center;
  border-radius: 40px;
  letter-spacing: 0.05em;
  margin: 0;
  cursor: pointer;
  @extend %font-special;
  @include animate;
  i.icon{
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 25px;
    transform: translateY(-50%);
    text-align: center;
    width: calc(1em + 40px);

    & ~ span{
      padding-left: calc(1em + 40px);
    }
  }
  span{
    display: block;
    padding: 8px 2em;
    line-height: 1em;
  }
  &:hover{
    @extend %gradient;
    color: $color-white;
    border-color: $color-main;
  }
}

%button-black{
  background: $color-black !important;
  color: $color-white;
  border-color: $color-black;

  &:hover{
    border-color: $color-white;
    background: $color-white !important;
    color: $color-black;
  }
}

%button-primary{
  border: 0;
  background: $color-main;
  color: #fff;
  font-weight: 400;
  &:hover{
    background: darken($color-main, 20%);
  }
}

%more{
  $w: 50px;
  display: block;
  height: $w;
  min-width: $w;
  display: block;
  margin: 0;
  background: #fff;
  border-radius: $w/2;
  text-align: center;
  line-height: $w;
  @extend %font-special;

  i{
    position: absolute;
    top: 0;
    left: 0;
    width: $w;
    font-style: normal;
    font-size: 30px;
    @include animate;
  }

  span{
    display: inline-block;
    overflow: hidden;
    width: 1px;
    white-space: nowrap;
    text-indent: $w - 10px;
    color: $color-white;
    padding-right: 10px;
    font-size: 14px;
  }
}

%title{
  @extend %font-special;
  @include rem(font-size, 80px);
  position: relative;
  color: $color-black;
  text-align: center;
  margin-bottom: $gutter-section;
  text-shadow: 2px 2px 0px rgba(0,0,0,0.1);
}

%title-2{
  @extend %title;
  @include rem(font-size, 50px);

  @include mq-s{
    @include rem(font-size, 32px);
  }
}

%gradient{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#26a943+0,007cc2+100 */
  background: rgb(38,169,67); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(38,169,67,1) 0%, rgba(0,124,194,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(38,169,67,1) 0%,rgba(0,124,194,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(38,169,67,1) 0%,rgba(0,124,194,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26a943', endColorstr='#007cc2',GradientType=1 ); /* IE6-9 */
}


/***************************************************************************************

  MAIN

***************************************************************************************/

*{
  box-sizing: border-box;
  outline: 0;
  //outline: 1px dotted magenta;
}

h1,h2,h3,h4,h5,h6,figure,dd,dt,dl{
  margin: 0;
  padding: 0;
  line-height: 1em;
}

p{
  margin-top: 0;
  a.more{
    @extend %button;
    margin-top: 1em;
  }
}

em{
  font-style: normal;
  font-weight: 800;
}

dl{
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  @include rem(font-size, 12px);

  .product-content &:last-child{
      border: 0;
  }

  dt{
    width: 50%;
    @include rem(padding, $gutter/2 0);
  }
  dd{
    @include rem(padding, $gutter/2 0);
    font-weight: bold;
  }
}

a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
  &:visited{
    text-decoration: none;
  }
  &:active{
    text-decoration: none;
  }
}

#loader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #fff;
  z-index: 10000000;
  text-align: center;

  > div{
    position: relative;
    top: 45%;
    margin: auto;
    display: inline-block;
    transform: rotate(45deg);
  }
}

#page{
  .main-header{
    background: #fff;
    > .wrapper{

    }
  }
  .main-body{
    > div{
      header{
        > .wrapper {

        }
      }
    }
  }

  .main-footer{
    background: #1a1a1a;

    &:before{
      content: "";
      position: absolute;
      bottom: 100%;
      right: 50%;
      background: url(../images/personnages.png) left top no-repeat;
      background-size: auto 150%;
      height: 60px;
      width: 200px;
      z-index: 100;
      transform: translateX(50%);
    }

    &:after{
      content: "";
      position: absolute;
      bottom: 100%;
      right: 0;
      @extend %gradient;
      height: 10px;
      width: 100vw;
      z-index: 50;
    }

    > div {

      .logo{
        height: 120px;
      }

      .address{
        color: $color-white;

        span{
          font-size: 0.8em;
        }

        [itemprop="addressLocality"]{
          display: block;
        }
      }
      .contact{
        padding-top: $gutter;
        a{
          @extend %button;
          border-color: $color-white;
          color: $color-white;
        }
      }
    }
  }
}

/***********************

FOOTER WHY

***********************/
.footer-why-us {
  background: #fff;
  //border-top: 1px solid #ddd;
  //@include rem(margin-top, $gutter);
  > .wrapper {
    @include grid-4;
    > div {
      @include rem(padding, $gutter);
      margin-bottom: 0 !important;
      h6 {
        @include rem(font-size, 16px);
        @include rem(margin-top, $gutter / 4);
        color: $color-main;
        font-weight: 600;
      }
      i{
        display: block;
        margin: auto;
        @include rem(margin-bottom, $gutter);
        @include rem(margin-right, $gutter);
        font-size: 30px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        //border: 1px solid $color-main;
        color: $color-main;
        float: left;
        text-align: center;
        background: lighten($color-main, 40%);
      }
      p {
        @include rem(font-size, 13px);
        margin-top: 0;
      }
    }
  }
}

.footer-logo{
  width: 180px;
}

.footer-payments{
  @include clearlist;
    > li{
      display: inline-block;
      margin: 0 $gutter / 2;
      img{
        width: 60px;
      }
    }
}

/***********************

FOOTER LINKS

***********************/
.footer-links {
  @include rem(margin-top, $gutter * 2);
  > .wrapper {
    @extend %wrapper;
    @include grid-4;
    > div {
      h6 {
        @include rem(margin-top, $gutter);
        @include rem(margin-bottom, $gutter/2);
        color: #fff;
        font-weight: 600;
        @include rem(font-size, 16px);
        text-transform: uppercase;
      }
      ul {
        @include clearlist;
        li {
          a {
            @include rem(font-size, 12px);
            color: $color-main-light;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/***********************

FOOTER SHARE

***********************/
.footer-share {
  position: relative;
  z-index: 200;
  @include rem(margin-top, $gutter * 2);
  > .wrapper {
    @extend %wrapper;
    padding-bottom: $gutter * 4;
    > div {
      ul {
        @include clearlist;
        text-align: center;
        li {
          display: inline-block;
          margin: 0 $gutter;
          margin-right: 0;
          a {
            font-size: 22px;
            color: $color-black;
            text-decoration: none;
            //border: 2px solid $color-secondary;
            background: #f7f7f7;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 56px;
            text-align: center;
            display: block;

            &:hover{
              @extend %gradient;
              color: $color-white;
            }
          }

          &:first-child{
            @include rem(font-size, 50px);
            @extend %font-special;
            color: #fff;

            @include mq-s{
              @include rem(font-size, 30px);
            }

            @extend %gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
}


/***********************

FOOTER COPYRIGHT

***********************/
.footer-copyright {
  > .wrapper {
    @extend %wrapper;
    //@include rem(padding, $gutter 0 !important);
    //@include rem(padding, $gutter 0 !important);
    display: flex;
    flex-wrap: wrap;
    > div {

      &.site-info{
        margin: auto;
        width: 100%;
        text-align: center;
        @include rem(font-size, 12px);
        padding: $gutter 0;
        color: #aaa;
        p{
          max-width: 800px;
          margin: auto;
        }
      }

      color: #aaa;
      a {
        color: #aaa;
      }

      &.customer {
        @include rem(font-size, 13px);
        margin-left: auto;
        margin-right: $gutter / 2;
        opacity: 0.5;
        text-transform: uppercase;
      }
      &.maven{
        @include rem(font-size, 9px);
        margin-right: auto;
        margin-left: $gutter / 2;
        span{
          text-transform: uppercase;
          line-height: 8px;
          display:inline-block;
          text-align: right;
          opacity: 0.5;
          margin-right: 2px;
        }
        svg{
          fill: #aaa;
          height: 14px !important;
        }
      }
    }
  }
}


.header-logo{
  position: absolute;
  left: $gutter;
  top: 0;
  display: inline-block;
  z-index: 15000;
  a{
    position: relative;
    width: 180px;
    display: block;
    overflow: hidden;
    img, svg{
      position: relative;
      width: 85%;
      height: auto;
      display: block;
      z-index: 10;
      margin: 0 auto 10% auto;
      transform: translateX(5px);
    }
    h1, strong{
      @include rem(font-size, 11px);
      font-weight: 300;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      width: 1px;
      height: 1px;
      white-space: nowrap;
      overflow: hidden;
    }
    &:before{
      content:"";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 2px rgba(0,0,0,0.3);
      margin-top: -6px;

      @include mq-s{
       //display: none;
        //margin-left: -10%;
      }
    }

    .navigation-minified &{
      width: 100px;
      height: 100px;
      overflow: inherit;
    }
  }
}

.main-navigation{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  flex-direction: column;
  padding-top: $gutter;
  padding-right: $gutter;

  .navigation-minified &{
    position: fixed;
    top: 60px;
    height: calc(100% - 60px);
    max-width: 100vw;
    min-width: 300px;
    width: 100%;
    background: rgba(0,0,0,0.5);
    left: 100%;
    z-index: 10000;
    display: flex;
    padding: 0;

    .navigation{
      display: flex;
      height: 100%;
      margin: auto;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      overflow-x: hidden;
      padding: $gutter;

      ul.nav-main{
        flex-wrap: wrap;
        //align-self: center;
        > li{
          width: 100%;

          a{
            color: #fff !important;
          }
          &.active{
            background: rgba(255,255,255,0.1);
          }
          &:hover {
            a:before {
              display: none !important;
            }
          }

          &.donation{
            width: 100%;
            background: none !important;
            margin-top: $gutter;
            margin-bottom: $gutter;
            &:before{
              display: none;
            }
            a{
              @extend %button;
              @extend %gradient;
              color: #fff;
            }
          }
          &.share{
            width: auto;
            margin: auto;
            &:hover{
              background: none;
            }
          }
        }
      }
    }
  }

  .top, .navigation{
    margin-left: auto;
  }
  .top{
    margin-top: $gutter;
    display: none;
  }

  ul.nav-main{
    @include clearlist;
    display: flex;
    align-items: center;

    > li{
      text-align: center;
      cursor: pointer;

      &:hover{
        background: rgba(0,0,0,0.05);
      }

      &.donation{
        background: $color-black;
        position: relative;
        a{
          @extend %font-special;
          text-transform: none;
          color: $color-white;
          padding-right: 10px;
        }
        &:before{
          content:"";
          position: absolute;
          bottom: 100%;
          left: 0;
          height: $gutter;
          width: 50vw;
          background: $color-black;
        }
        i{
          position: absolute;
          right: 49px;
          top: 30px;
          font-style: normal;
          transform: rotate(69deg) translateY(-50%) translateX(-50%);
          font-size: 40px;
        }
      }

      &.donation:hover{
        @extend %gradient;
        a{
          color: $color-white !important;
        }
        &:before{
          background: $color-black;
        }
      }

      &.share{
        display: flex;
        &:hover{
          background: none;
        }
        a{
          margin-left: $gutter / 2;
        }
        span{
          width:40px;
          height: 40px;
          border-radius: 50%;
          background: #f7f7f7;
          color: $color-black;
          text-align: center;
          line-height: 38px !important;
          padding: 0;
          font-size: 18px;
        }

        a:before{
          display: none !important;
        }
        a:hover{
          span{
            @extend %gradient;
            color: $color-white;
          }
        }
      }

      > a{
        @include rem(font-size, 18px);
        white-space: nowrap;
        position: relative;
        color: $color-black;
        display: block;
        font-weight: 100;
        cursor: pointer;
        @include animate;

        span{
          display: block;
          padding: 0 1em;
          line-height: 2em;
        }

        &:hover{
          &:before{
            width: 100%;
          }
          color: $color-main;
        }
      }
      &.active a{
        color: $color-main;
        font-weight: 600;
      }
    }
  }
  .icon{
    font-size: 26px;
    width: 60px;
    padding: 0;
    text-align: center;
  }

  .navigation ul.nav-main{
    @include clearlist;
    display: flex;
    align-items: center;

    > li{
      text-align: center;

      > a{

        span{
          line-height: 60px;
        }

        &:before{
          content:"";
          @extend %gradient;
          position: absolute;
          bottom: -$gutter/4;
          left: 0;
          width: 0;
          height: $gutter/4;
          z-index: 100;
          @include animate;
        }

        &:hover{
          &:before{
            width: 100%;
          }
          color: $color-black;
        }
      }
    }
  }
}

.main-navigation .navigation ul.nav-main > li{

  .navigation-minified.desktop &:hover{
    background: rgba(255,255,255,0.1);
  }

  .navigation-minified.mobile &:hover,
  .navigation-minified.desktop &:active{
    background: rgba(255,255,255,0.9);
    a{
      color: #000 !important;
    }
  }

}


.banner{
  position: relative;
  background: #000;
  z-index: 10;

  &:after{
    content: "";
    position: absolute;
    bottom: - $gutter;
    right: 66%;
    background: $color-black;
    height: $gutter;
    width: 50vw;
    //transform: translateX(-50%);
    z-index: 100;
  }

  &:before{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../images/banner-gradient.png') left center no-repeat;
    background-size: 50% 101%;
    z-index: 10;

    @include mq-s{
      background-size: cover;
    }
  }

  .bg{
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    background: $color-black no-repeat center;
    background-size: cover;
    z-index: 0;
  }

  > .wrapper{
    @extend %wrapper;
    min-height: 80vh;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 10;

    &:before{
      content: "";
      position: absolute;
      bottom: 0;
      left: 66%;
      background: #fff;
      height: $gutter;
      width: 50vw;
      z-index: 100;
    }

    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: $gutter;
      background: url('../images/personnages.png') left top no-repeat;
      background-size: auto 150%;
      height: 140px;
      width: 500px;
      z-index: 100;

      @include mq-s{
        height: 60px;
        width: 200px;
        background-size: auto 150%;
      }
    }

    .page-category &{
      height: 20vh;
      min-height: 20vh;
    }

    h1{
      @extend %font-special;
      @include rem(font-size, 80px);
      color: #fff;

      @include mq-s{
        @include rem(font-size, 40px);
      }
    }

    .image{
      transform: translateY(#{$gutter-section});
      width: 50%;
      margin-left: auto;
      box-shadow: -5px 5px 20px rgba(0,0,0,0.3);
      figure{
        background: $color-black no-repeat center;
        background-size: cover;
        img{
          width: 100%;
          height: auto;
        }
      }
    }

    .content{
      //align-self: center;
      position: relative;
      z-index: 100;
      font-weight: 100;
      width: 50%;
      color: $color-black;

      @include mq-s{
        width: 100%;
        text-align: center;
      }

      p{
        font-weight: 600;
        color: $color-black;
        line-height: 1.2em;
        @include rem(font-size, 30px);
        strong{
          display: block;
          font-size: 2.4em;
          line-height: 1em;
          @extend %font-special;
          color: $color-white;
          text-shadow: 2px 2px 2px $color-black;
          & ~ span{
            //@extend %font-special;
            //font-size: 1.2em;
          }
        }
        span{
          display: block;
        }

        &.more{
          //text-align: center;
        }

        @include mq-s{
          @include rem(font-size, 20px);
        }
      }

      a{
        @extend %button;
        display: inline-block;
        border: 2px solid #fff;
        text-align: center;
        line-height: 50px;
        color: #fff;
        padding: 0 40px;
        margin: auto;
      }
      .page-homepage &{
        width: 40%;
      }
    }
  }

  &.interior{
    @extend %gradient;

    &:before{
      background-size: 100% 101%;
    }

    .bg{
      filter: grayscale(100%);
      opacity: 0.5;
    }

    h1{
      @include rem(font-size, 60px);
      margin-bottom: 0.4em;

      @include mq-s{
        @include rem(font-size, 40px);
      }
    }

    > .wrapper{
      min-height: 500px;

      &:after{
        height: 60px;
        width: 200px;
      }

      .content{
        text-align: center;
        width: 100%;
        padding-top: 80px;

        p{
          margin-left: auto;
          margin-right: auto;
          max-width: 700px;
          @include rem(font-size, 22px);
        }
      }
    }
  }

  &.project{

    figure{
      opacity: 0.5;
    }

    &:before{
      background-size: 100% 101%;
      display: none;
    }

    > .wrapper{
      min-height: 50vh;

      &:after{
        display: none;
      }

      .content{
        text-align: center;
        width: 100%;
      }
    }
  }
}


section{
  position: relative;
  > header{
    position: relative;
    z-index: 10;
    padding-top: $gutter-section;
    text-align: center;
    > h2 {
      @extend %title-2;
      color: $color-main;
    }
    > p{
      //margin-bottom: $gutter-section;
      max-width: 600px;
      margin: auto;
      margin-bottom: $gutter-section;
    }
  }
  > footer{
    position: relative;
    z-index: 10;
    padding-bottom: $gutter-section;
    text-align: center;
    a{
      @extend %button;
      &.subscribe{
        font-size: 1.5em;
        border-color: $color-main;
        background: $color-main;
        color: $color-white;
      }
    }
  }
  > .wrapper{
    @extend %wrapper;
    position: relative;
    z-index: 10;
    padding-top: $gutter-section;
    padding-bottom: $gutter-section;
    @include grid-auto($gutter * 4);

    > *{
      @include mq-s{
        width: 100%;
        flex: auto;
      }
    }

    p + h2, p + h3{
      margin-top: 1em;
    }

    h3{
      @include rem(font-size, 28px);
      margin-bottom: 0.5em;
    }

    h2{
      @include rem(font-size, 34px);
      margin-bottom: 1.5em;
      font-weight: 400;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: 100%;
        height: 2px;
        width: 60px;
        margin-top: 0.5em;
        @extend %gradient;
      }
    }
  }
}


.filters{
  text-align: center;
  a{
    position: relative;
    display: inline-block;
    border-radius: 3em;
    @extend %font-special;
    text-align: center;
    border: 1px solid $color-black;
    line-height: 2em;
    padding: 0 1em;
    margin: 0 $gutter;
    color: $color-black;
    @include rem(font-size, 20px);
    @include animate;

    &:before{
      content: "";
      position: absolute;
      top: 95%;
      left: 50%;
      transform: translateX(-50%);
      border: 14px solid transparent;
      border-top-color: #000;
      display: none;
    }

    &:hover{
      background: $color-black;
      color: #fff;
      transform: rotate(-1deg) scale(1.15);
      box-shadow: 2px 3px 3px rgba(0,0,0,0.1);
    }

    &.tag-children{
      color: $color-third;
      border-color: $color-third;
      &:before{
        border-top-color: $color-third;
      }
      &.active,
      &:hover{
        background:  $color-third;
        color: #fff;
      }
    }
    &.tag-earth{
      color: $color-main;
      border-color: $color-main;
      &:before{
        border-top-color: $color-main;
      }
      &.active,
      &:hover{
        background: $color-main;
        color: #fff;
      }
    }
    &.tag-water{
      color: $color-secondary;
      border-color: $color-secondary;
      &:before{
        border-top-color: $color-secondary;
      }
      &.active,
      &:hover{
        background: $color-secondary;
        color: #fff;
      }
    }

    &.active{
      &:before{
        display: block;
      }
    }
  }

  &.project{
    a{
      border-width: 0px;
      color: $color-black;
      margin: 0 $gutter / 2;
      &.active{
        border-width: 1px;
      }
    }
  }
}


.hugo-project-resume{
  > .wrapper{
    @extend %wrapper;
    display: block;

    .content{
      position: relative;
      //padding: $gutter * 2;
      transform: translateY(-50%);
      box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
      @extend %gradient;
      display: flex;
      @include grid-4-no;
      min-height: 100px;

      @include mq-s{
        transform: none;
      }

      > div{
        display: flex;
        flex-wrap: wrap;
        color: $color-white;
        padding: 0 $gutter;
        @include rem(font-size, 26px);

        > label{
          @extend %font-special;
          width: 100%;
          display: block;
          padding: $gutter;
          text-shadow: 2px 2px 0px rgba(0,0,0,0.2);
          //font-weight: 600;
        }
        > span{
          width: 100%;
          display: block;
          padding: $gutter;
          text-align: right;
          padding-top: 0;
        }
      }

      .resume-themes{
        background: rgba(255,255,255,1);
        width: 50%;

        @include mq-s{
          width: 100%;
        }

        label{
          color: $color-black;
        }
        .likes {
          display: flex;
          float: right;
          margin-left: auto;
          width: auto;
          color: $color-black;
          align-items: center;
          i{
            margin-left: $gutter;
            text-align: center;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 36px;

            &:first-child{
              margin-left: auto;
            }
          }
        }
        .tags{
          display: flex;
          float: left;
          width: auto;
          i{
            margin-right: $gutter;
            text-align: center;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            line-height: 60px;
            color: #fff;
            font-size: 36px;
            background: #eee;

            &.tag-children.active{
              background: $color-third;
            }
            &.tag-earth.active{
              background: $color-main;
            }
            &.tag-water.active{
              background: $color-secondary;
            }
          }
        }
      }

      .resume-price{
        padding: $gutter;
        label{
          padding: 0;
        }
        span{
          @include rem(font-size, 18px);
          padding: 0;
          i{
            //float: left;
            transform: translateY(-4px);
            font-style: normal;
            font-weight: 100;
            font-size: 0.5em;
          }
        }
        div{
          display: flex;
          align-items: flex-end;
          width: 100%;

          span{
            align-self: center;
          }
          > span:last-child{
            margin-left: auto;
            text-align: right;
            font-weight: 600;
            font-size: 1.1em;
          }
        }
      }

      .resume-donate{
        background: rgba(0,0,0,0.15);
        align-items: center;
        padding: 0;
        flex-direction: column;
        a{
          width: 100%;
          color: $color-white;
          height: 50%;
          display: flex;
          align-items: center;
          text-align: center;
          @include rem(font-size, 24px);
          @extend %font-special;

          span{
            padding: 0;
            display: block;
            width: 100%;
          }
          &:last-child{
            @extend %gradient;
            background: $color-black;
          }
        }
      }
    }
  }
  & + section{
    background: none;
  }
}

.hugo-gallery{
  > .wrapper{
    @extend %wrapper;
    padding-bottom: $gutter-section;

    ul{
      @include clearlist;
      column-gap: $gutter;
      column-count: 4;
      font-size: 0;
      li{
        display: inline-block;
        margin-bottom: $gutter;
        figure{
          img{
            width: 100%;
          }
        }
      }
    }
  }
}

.hugo-mission{
  background: #fff;
  header .filters{
    margin-bottom: $gutter-section;
  }
  footer{
    padding-top: $gutter-section;
  }
  > .wrapper{
    @extend %wrapper;
    @include grid-2-no;
    min-height: 50vh;

    > *{
      margin-bottom: 0px !important;
      position: relative;
      z-index: 10;
    }

    figure{
      background: no-repeat #000 center;
      background-size: cover;
      height: 100%;
    }

    .image{
      @include mq-s{
        min-height: 200px;
        order: 1 !important;
        margin-bottom: $gutter !important;
      }
    }

    .content{
      align-self: center;
      padding: $gutter * 4;

      @include mq-s{
        padding: 0;
        order: 2 !important;
      }
    }

    &:nth-child(odd){
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: $gutter;
        width: 50vw;
        background: $color-main;
        z-index: 100;
        transform: translateY(-50%);
        margin-left: $gutter * 4;
      }

      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        width: 100vw;
        transform: translateX(-50%);
        background: lighten($color-main-light, 10%);
      }
      .content{
        order: 2;
      }
      .image{
        order: 1;
      }
    }

    &:last-child{
      &:after{
        background: $color-secondary-light !important;
      }
      &:before{
        background: $color-secondary;
      }
    }
  }
}

.hugo-projects{
  position: relative;
  background: #f2f2f2;

  &:before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    height: $gutter;
    width: 33.33%;
    z-index: 100;
  }

  > .wrapper {

    ul{
      @include clearlist;
      @include grid-3;
      li{
        background: $color-white;
        box-shadow: 0px 5px 5px rgba(0,0,0,0.05);

        opacity: 0;
        transform: translateY(100px);
        @include animate;

        &.animate{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    article{
      display: flex;
      flex-direction: column;

      > *{
        padding: 0 $gutter;
      }

      figure{
        position: relative;
        margin: 0;
        padding: 0;
        margin-bottom: $gutter;
        img{
          width: 100%;
          display: block;
        }

        &:before{
          content:"";
          position: absolute;
          bottom: 100%;
          left: 0;
          height: $gutter / 2;
          width: 50%;
          background: $color-black;
          z-index: 10;
        }

        &:after{
          content:"";
          position: absolute;
          bottom: 0;
          right: 0;
          height: $gutter / 2;
          width: 50%;
          background: $color-white;
          z-index: 10;
        }
      }

      h3{
        @include rem(font-size, 26px);
        margin-bottom: 0.5em;
        a{
          color: $color-black;
        }
      }

      time{
        @include rem(font-size, 13px);
        margin-bottom: 0.5em;
        color: lighten($color-black, 20%);

        a{
          font-weight: bold;
          color: $color-main;
        }
      }

      .more{
        @extend %button;
        margin: $gutter auto;
        display: inline-block;
      }
    }
  }
}

.hugo-presentation{
  background: #fff;
  > .wrapper{
    padding-top: 0;
    padding-bottom: 0;
    min-height: 600px;
    position: relative;
    display: flex;

    @include mq-s{
      flex-wrap: wrap;
    }

    .bg{
      position: absolute;
      top: 10%;
      left: 30%;
      width: 50%;
      bottom: 5%;
      z-index: 1;
      background: no-repeat center;
      background-size: 80% auto;

      @include mq-s{
        background-size: 60% auto;
        width: 100%;
        left: 0;
      }
    }

    .content{
      position: relative;
      z-index: 10;
      width: 66.666%;
      padding: $gutter * 2;
      padding-right: 30%;
      align-self: center;

      @include mq-s{
        width: 100%;
        text-align: center;
        padding: $gutter-section $gutter;
      }

      .more{
        background: #fff;
        @extend %button;
      }

      h1{
        @include rem(font-size, 50px);
        line-height: 1em;
        span{
          display: block;
          font-size: 0.6em;
          font-weight: 100;
        }
      }
    }

    .objectives{
      position: relative;
      z-index: 10;
      width: 33.333%;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.9+19,0+100 */
      background: -moz-linear-gradient(left, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 19%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.9) 19%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.9) 19%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
      ul{
        @include clearlist;
        display: flex;
        flex-direction: column;
        height: 100%;
        li{
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;

          article{
            position: relative;
            z-index: 10;
            padding: $gutter * 2;
            padding-left: $gutter * 3;

            p{
              display: none;
            }

            h3{
              @include rem(font-size, 24px);
              text-shadow: 0px 0px 5px $color-white;
              a{
                color: $color-secondary;
              }

              @include mq-s{
                @include rem(font-size, 18px);
                text-align: left;
              }
            }
          }

          .more{
            @extend %more;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) translateX(-25px);
          }

          &:before{
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 200%;
            background: rgba($color-secondary, 0.15);
            @include animate;
            z-index: 1;
          }

          &:nth-child(even){
            &:before{
              background: rgba($color-main, 0.15);
            }
            article{
              h3{
                a{
                  color: $color-main;
                }
              }
            }
            &:hover {
              &:before {
                @extend %gradient;
              }
            }
          }

          &:hover{
            &:before{
              @extend %gradient;
            }
            .more{
              background: $color-black;
              i{
                color: $color-white;
              }
              span{
                width: auto;
              }
            }
            article{
              padding-left: $gutter * 2 + 80px;
              h3 a{
                text-shadow: none;
                color: $color-white;
              }
            }
          }
        }
      }

      @include mq-s{
        width: 100%;
        text-align: center;
        padding: $gutter;
        margin-top: 100px;
      }
    }
  }
}

.iban{
  border-top: $color-black 1px solid;
  //@extend %gradient;
  background: #fff;
  color: $color-black;
  text-align: center;
  padding: $gutter*2;
  @include rem(font-size, 18px);
  p{
    margin-bottom: 0;
  }
  strong{
    font-size: 1.4em;
  }
  h3{
    span{
      display: block;
      font-size: 0.8em;
      font-weight: 300;
    }
  }
}

.hugo-agenda{
  position: relative;
  background: $color-main;

  .bg{
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    z-index: 0;
    transform: translateX(-50%);
    filter: grayscale(100%);
    opacity: 0.1;
    background: no-repeat center;
    background-size: cover;

    @include mq-s{
      display: none;
    }
  }

  &:before{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../images/banner-gradient.png') left center no-repeat;
    background-size: 50% 100%;
    z-index: 10;

    @include mq-s{
      background-size: cover;
    }
  }

  &:after{
    content: "";
    position: absolute;
    top: 0;
    right: 66%;
    background: #f2f2f2;
    height: $gutter;
    width: 50vw;
    z-index: 100;
  }
  > header{
    h2{
      color: #fff;
    }
  }
  > .wrapper{
    z-index: 10;
    @include clearfix;
    display: block;

    @include mq-s{
      padding-top: 0 !important;
    }

    article{
      position: relative;
      float: left;
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 80px;
      margin-bottom: $gutter;
      color: $color-white;

      @include mq-s{
        padding-top: 0 !important;
      }

      @include mq-s{
        width: 100%;
        padding: 0 $gutter;
        padding-left: 80px;
      }

      p{
        opacity: 0.7;
      }

      figure{
        margin-bottom: $gutter;
        img{
          width: 100%;
          display: block;
        }
      }

      h3{
        @include rem(font-size, 24px);
        margin-bottom: 0.5em;

        @include mq-s{
          @include rem(font-size, 18px);
        }
      }

      time{
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: center;
        @include rem(font-size, 28px);
        line-height: 0.5em;
        padding-top: 14px;
        margin-top: 2px;
        //border: 2px solid $color-white;
        background: rgba($color-black, 0.3);

        span{
          margin-bottom: 4px;
          margin-top: 5px;
          display: block;
          font-size: 0.5em;
          font-weight: 100;
          text-transform: uppercase;
          line-height: 1.3em;
        }
      }

      &.highlight{
        min-height: 400px;
        padding-right: 5%;
        padding-left: 100px;
        color: $color-black;

        @include mq-s{
          width: 100%;
          padding: 0 $gutter;
          padding-left: 80px;
        }

        h3{
          @include rem(font-size, 30px);
          margin-bottom: 1em;

          @include mq-s{
            @include rem(font-size, 22px);
          }
        }

        p{
          opacity: 1;
        }

        time{
          background: #fff;
          width: 80px;
          height: 80px;
          @include rem(font-size, 46px);
          padding-top: 18px;

          @include mq-s{
            padding-top: 14px;
            width: 60px;
            height: 60px;
            @include rem(font-size, 32px);
          }
        }
      }

    }

  }
}


.hugo-help{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f2f2f2+100 */
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  &:after{
    content: "";
    position: absolute;
    top: - $gutter;
    left: 66%;
    background: $color-black;
    height: $gutter;
    width: 50vw;
    z-index: 100;
  }

  > .wrapper{
    display: block;
    padding-top: 0;
    padding-bottom: $gutter-section;

    .homepage &{
      padding-bottom: 0;
    }

    figure{
      display: block;
      transform: rotate(-1deg);
      border: 10px solid $color-white;
      box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
      img{
        width: 100%;
      }

      opacity: 0;
      transform: translateY(200px);
      @include animate;

      &.animate{
        opacity: 1;
        transform: translateY(0);
      }
    }

    > div{
      .homepage &{
        transform: translateY(-150px);
        padding: 0 $gutter * 2;
      }

      @include mq-s{
        transform: none !important;
        padding: 0 !important;
      }
    }

    ul{
      @extend %gradient;
      @include grid-4-no;
      padding: 0;
      li{
        position: relative;
        cursor: pointer;
        text-align: center;
        padding: $gutter * 2;
        color: $color-white;
        @include animate;

        &:before{
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          height: 60%;
          width: 1px;
          background: $color-black;
          transform: translateY(-50%);
          opacity: 0.5;

          @include mq-s{
            top: 100%;
            height: 1px;
            width: 100%;
          }
        }

        &:after{
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          height: 60%;
          width: 1px;
          background: $color-white;
          transform: translateY(-50%);
          opacity: 0.3;

          @include mq-s{
            top: 100%;
            height: 1px;
            width: 100%;
            margin-top: 1px;
          }
        }

        &:first-child{
          &:after{
            display: none;

            @include mq-s{
              display: block;
            }
          }
        }
        &:last-child{
          &:before{
            display: none;
          }
        }

        &:nth-child(even){
          //background: $color-main;
        }

        h3{
          @include rem(font-size, 24px);
          margin-bottom: 0.5em;
        }

        p{
          opacity: 0.7;
        }

        &:hover{
          background: rgba($color-black, 0.1);
        }

        .more{
          @extend %more;
          opacity: 1;
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
          color: $color-white;
          background: $color-black;

          @include mq-s{
            bottom: 50%;
            right: -25px;
            transform: translateY(50%);
            left: inherit;
          }
        }
      }
    }
  }
}





.hugo-introduction{
  background: $color-white;
  > .wrapper{
    @extend %wrapper;
    @include grid-2($gutter*4);
    padding: 2rem;
    padding-top: $gutter-section;
    padding-bottom: $gutter-section;

    figure{
      transform: rotate(-1deg);
      border: 10px solid $color-white;
      box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
      img{
        width: 100%;
      }
    }

    > *{
      align-self: center;
      margin-bottom: 0px !important;
    }
  }
}

.hugo-story{
  > header{
    p{
      font-size: 30px;
    }
  }
  > .wrapper{
    @extend %wrapper;
    @include grid-2($gutter*4);
    padding: 2rem;
    //padding-top: $gutter-section;
    padding-bottom: $gutter-section;

    figure{
      transform: rotate(-1deg);
      border: 10px solid $color-white;
      box-shadow: 0px 5px 5px rgba(0,0,0,0.05);
      img{
        width: 100%;
      }
    }

    > *{
      align-self: center;
      margin-bottom: 0px !important;
    }

    .content{
      @include mq-s{
        column-count: 1 !important;
      }
    }
  }
}


.hugo-activities{
  background: $color-white;

  > .wrapper{
    @extend %wrapper;
    padding-bottom: $gutter-section;

    ul{
      @include clearlist;
      @include grid-3($gutter*4);
      li{
        background: rgba(0,0,0,0.04);
        text-align: center;
        padding: $gutter;
        i{
          @extend %gradient;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include rem(font-size, 80px);
          margin-bottom: 0.5em;
        }
        h3{
          @include rem(font-size, 26px);
          margin-bottom: 0.5em;
        }

        opacity: 0;
        transform: translateY(100px);
        @include animate;

        &.animate{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}


.hugo-team{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edfff0+23,edfff0+100&0+0,1+100 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(237,255,240,0) 0%, rgba(237,255,240,0.23) 23%, rgba(237,255,240,1) 100%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(237,255,240,0) 0%,rgba(237,255,240,0.23) 23%,rgba(237,255,240,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(237,255,240,0) 0%,rgba(237,255,240,0.23) 23%,rgba(237,255,240,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00edfff0', endColorstr='#edfff0',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 66%;
    background: $color-main;
    height: $gutter;
    width: 50vw;
    z-index: 100;
  }

  > .wrapper{
    @extend %wrapper;
    padding-top: $gutter-section;
    padding-bottom: calc(#{$gutter-section} + 100px);

    ul{
      @include clearlist;
      @include grid-4($gutter*4);
      li{
        text-align: center;
        padding: $gutter;

        transform: rotate(-2deg);

        &:nth-child(even){
          transform: rotate(2deg) translateY(10px);
        }

        &:nth-child(3n){
          transform: scale(1.1) rotate(2deg) translateY(10px);
        }

        a{
          @include animate;
          position: absolute;
          top: 50%;
          right: 10px;
          background: $color-secondary;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          line-height: 45px;
          color: #fff;
          border: 3px solid #fff;

          &:hover{
            transform: scale(1.1);
          }
        }

        figure{
          background-color: rgba(0,0,0,0.05) !important;
          margin: auto;
          min-width: 200px;
          border-radius: 50%;
          overflow: hidden;
          background-size: cover;
          background-repeat: no-repeat;
          margin-bottom: 1em;
          border: 10px solid #fff;
          //box-shadow: 6px 2px 0px #7ED68D, -4px -1px 0px #4D99C1;
          box-shadow: 6px 2px 5px rgba(0,0,0,0.05);
          img{
            width: 100%;
            height: auto;
          }
        }

        h3{
          @include rem(font-size, 22px);
          margin-bottom: 0.5em;

          span:first-child{
            @extend %gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        p.title{
          @extend %font-special;
          @include rem(font-size, 20px);
          color: #888;
          margin-top: -0.5em;
        }
      }
    }

  }
}



.hugo-join-us{
  position: relative;
  z-index: 100;
  margin-bottom: -100px;
  > .wrapper{
    @extend %wrapper;
    //padding-top: $gutter-section;

    .content{
      position: relative;
      text-align: center;
      transform: translateY(-50%);
      max-width: 600px;
      padding: $gutter * 2;
      margin: auto;
      color: $color-white;
      @extend %gradient;
      box-shadow: 0px 4px 5px rgba(0,0,0,0.2);

      a{
        @extend %button;
        @extend %button-black;
        margin-top: 1em;
        font-size: 1.4em;
      }

      h2{
        @extend %font-special;
        @include rem(font-size, 40px);
        text-shadow: 2px 2px 0px rgba(0,0,0,0.3);
      }
    }
  }
}