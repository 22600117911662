$screen-xxs: 320px;
$screen-xs: 640px;
$screen-s: 900px;
$screen-m: 1024px;
$screen-l: 1200px;
$screen-xl: 1600px;


$device-smartphone: 480px;
$device-tablet-mini: 767px;
$device-tablet: 900px;
$device-tablet-horizontal: 1024px;
$device-laptop: 1440px;



/* MEDIA QUERIES */
$screen: "only screen";
$print: "only print";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2)";
$mq-landscape: "(orientation : landscape)";
$mq-portrait: "(orientation : portrait)";

// DEVICE WIDTH
$mq-smartphone: #{$screen} and (max-device-width: #{$device-smartphone});
$mq-tablet-mini: #{$screen} and (max-device-width: #{$device-tablet-mini});
$mq-tablet: #{$screen} and (max-device-width: #{$device-tablet});
$mq-tablet-horizontal: #{$screen} and (max-device-width: #{$device-tablet-horizontal});
$mq-laptop: #{$screen} and (max-device-width: #{$device-laptop});


@mixin mq-xl {
  @media #{$screen} and (max-width: #{$screen-xl}) {
    @content;
  }
}

@mixin mq-l {
  @media #{$screen} and (max-width: #{$screen-l}) {
    @content;
  }
}

@mixin mq-m {
  @media #{$screen} and (max-width: #{$screen-m}) {
    @content;
  }
}

@mixin mq-s {
  @media #{$screen} and (max-width: #{$screen-s}) {
    @content;
  }
}

@mixin mq-xs {
  @media #{$screen} and (max-width: #{$screen-xs}) {
    @content;
  }
}